<template>
  <div class="user-management" ref="userManagement">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddUser"
        v-if="nowMenuList.indexOf('新增') >= 0"
        >新增</el-button
      >
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item label="用户姓名：">
          <el-input
            v-model.trim="form.userName"
            placeholder="请输入用户姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户名：">
          <el-input
            v-model.trim="form.loginName"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属公司：">
          <companySelect
            :clearable="true"
            :companyTree="companyList"
            :valueName="valueName"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="角色：">
          <el-select
            v-model="form.roleId"
            clearable
            placeholder="请选择所属角色"
          >
            <el-option
              v-for="role in roleList"
              :label="role.roleName"
              :value="role.id"
              :key="role.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="form.status" clearable placeholder="请选择状态">
            <el-option label="禁用" :value="1"></el-option>
            <el-option label="启用" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="small"
            :loading="searchLoading"
            @click="onSearch()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="userName" label="用户姓名"></el-table-column>
      <el-table-column prop="loginName" label="用户名"></el-table-column>
      <el-table-column prop="roles" label="角色" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.roleNames.join("、") }}
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="联系方式"></el-table-column>
      <el-table-column
        label="所属公司"
        prop="companyIds"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          {{ scope.row.companyNames.join("、") }}
        </template></el-table-column
      >
      <el-table-column label="操作" width="360" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <el-button
            @click="onUpdateUser(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="nowMenuList.indexOf('编辑') >= 0 && scope.row.status === 0"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            :sort="scope.row.status === 0 ? 'danger' : 'primary'"
            @click="onDisableUser(scope.row)"
            v-if="
              scope.row.status === 0
                ? nowMenuList.indexOf('禁用') >= 0
                : nowMenuList.indexOf('启用') >= 0
            "
            >{{ scope.row.status === 0 ? "禁用" : "启用" }}</el-button
          >
          <el-button
            type="text"
            size="small"
            sort="danger"
            @click="onResetPassword(scope.row)"
            v-if="
              nowMenuList.indexOf('重置密码') >= 0 && scope.row.status === 0
            "
            >重置密码</el-button
          >
          <el-button
            type="text"
            size="small"
            sort="primary"
            @click="unlockAccount(scope.row)"
            v-if="
              nowMenuList.indexOf('解锁账号') >= 0 && scope.row.status === 0 && scope.row.locking
            "
            >解锁账号</el-button
          >
          <el-button
            type="text"
            size="small"
            sort="primary"
            @click="onAuthorizedVeh(scope.row)"
            v-if="nowMenuList.indexOf('授权车辆') >= 0 && scope.row.type === 1"
            >授权车辆</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 新增|编辑 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      @close="onDialogClose"
      width="60%"
    >
      <div>
        <div class="body">
          <el-form
            :model="addForm"
            :rules="rules"
            ref="addForm"
            label-width="82px"
            label-position="left"
            class="demo-form dialog-form dialog-form-inline"
          >
            <el-form-item label="用户姓名：" prop="userName">
              <el-input
                v-model.trim="addForm.userName"
                placeholder="请输入用户姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户名：" prop="loginName">
              <el-input
                v-model.trim="addForm.loginName"
                placeholder="请输入用户名"
                :disabled="title === '修改用户信息'"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="密码："
              prop="password"
              v-if="title === '新增用户'"
            >
              <el-input
                v-model.trim="addForm.password"
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系方式：" prop="phone">
              <el-input
                v-model.trim="addForm.phone"
                placeholder="请输入手机号码"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属公司：" prop="companyIds">
              <companySelect
                ref="companySelect"
                :clearable="true"
                :multiple="true"
                :checkStrictly="false"
                :companyTree="companyList"
                :valueNameMultiple="valueNameMultiple"
                :valueMultiple="valueMultiple"
                @getValue="getGroupIds"
              ></companySelect>
            </el-form-item>
            <el-form-item label="权限公司：" prop="pcompanyId">
              <companySelect
                ref="authCompanySelect"
                :clearable="true"
                :valueName="valueAddName"
                :value="valueAdd"
                :companyTree="authCompanyList"
                @getValue="getAuthGroupId"
              ></companySelect>
            </el-form-item>
            <el-form-item label="高清视频：" prop="hdVideo">
              <el-select v-model="addForm.hdVideo" placeholder>
                <el-option label="不可看" :value="0"></el-option>
                <el-option label="可看" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="小程序：" prop="wxStatus">
              <el-select v-model="addForm.wxStatus" placeholder :disabled="!weChatChooseStatus">
                <el-option label="可登录" :value="0"></el-option>
                <el-option label="不可登录" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用户组：" prop="userGroupId">
              <el-select
                v-model="addForm.userGroupId"
                placeholder="请选择用户组"
              >
                <el-option
                  v-for="group in availableUserGroupList"
                  :label="group.name"
                  :value="group.id"
                  :key="group.id"
                  :disabled="group.status === 1"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="角色：" prop="roleIds">
              <el-select
                v-model="addForm.roleIds"
                multiple
                collapse-tags
                clearable
                placeholder="请选择所属角色"
              >
                <el-option
                  v-for="role in chooseRoleList"
                  :label="role.roleName"
                  :value="role.id"
                  :key="role.id"
                  :disabled="role.status === 1"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="车组账号：" prop="type">
              <el-select
                v-model="addForm.type"
                clearable
                placeholder="请选择车组账号"
              >
                <el-option
                  v-for="item in typeList"
                  :label="item.dictValue"
                  :value="Number(item.dictCode)"
                  :key="item.dictCode"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 授权车辆 -->
    <el-dialog
      title="授权车辆"
      :visible.sync="showAuthorizedVeh"
      v-if="showAuthorizedVeh"
      width="50%"
    >
      <div class="body">
        <authorizedVeh ref="authorizedVeh" :userId="userId"></authorizedVeh>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          type="default"
          size="small"
          @click="showAuthorizedVeh = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import md5 from 'js-md5'
import {
  checkPhone,
  checkUsername,
  checkPassword,
  checkName
} from '@/common/utils/index.js'

import companySelect from '@/components/treeSelect/companySelect.vue'
import authorizedVeh from './components/authorizedVeh.vue'
import {
  getUserList,
  disableUser,
  resetPwd,
  unLockUser,
  insertUser,
  updateUser,
  queryAllUserGroup,
  getBusinessCompanyTree,
  getAuthCompanyTree,
  getRoleLabelList,
  queryChooseRoles,
  queryDictsByCodes,
  queryWeChatChoose
} from '@/api/lib/api.js'
import { mapGetters } from 'vuex'

export default {
  name: 'userManagement',
  components: {
    companySelect,
    authorizedVeh
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      value: null,
      valueName: null,
      valueNameMultiple: null,
      valueMultiple: null,
      valueAddName: null,
      valueAdd: null,
      form: {
        userName: null,
        loginName: null,
        companyId: null,
        roleId: null,
        status: null,
        currentPage: 1,
        pageSize: 10
      },
      addForm: {
        id: '',
        type: 1,
        userName: '',
        loginName: '',
        password: '',
        phone: '',
        companyIds: [],
        pcompanyId: '',
        hdVideo: 0,
        wxStatus: 1,
        userGroupId: '',
        roleIds: []
      },
      rules: {
        userName: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' },
          { validator: checkName, trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        loginName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { validator: checkUsername, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 18, message: '密码为6-18位数字或字母', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' }
        ],
        companyIds: [{ required: true, message: '请选择所属公司', trigger: 'change' }],
        pcompanyId: [{ required: true, message: '请选择权限公司', trigger: 'change' }],
        hdVideo: [{ required: true, message: '请选择是否可观看高清视频', trigger: 'change' }],
        wxStatus: [{ required: true, message: '请选择是否可登录小程序', trigger: 'change' }],
        userGroupId: [{ required: true, message: '请选择用户组', trigger: 'change' }],
        roleIds: [{ required: true, type: 'array', message: '请选择所属角色', trigger: 'change' }],
        type: [{ required: true, message: '请选择是否是车组账号', trigger: 'change' }]
      },
      total: 0,
      dialogVisible: false,
      title: '',
      companyList: [],
      authCompanyList: [],
      availableUserGroupList: [],
      roleList: [],
      typeList: [],
      availableRoleList: [],
      searchLoading: false,
      editLoading: false,
      userId: null,
      showAuthorizedVeh: false,
      weChatChooseStatus:false,
      chooseRoleList:[],
    }
  },
  computed: {
    ...mapGetters({
      nowMenuList: 'nowMenuList'
    }),

  },
  methods: {
    //小程序是否可选
    queryWeChatChooseStatus(){
      queryWeChatChoose().then(res=>{
        this.weChatChooseStatus = res.data || false;
      }).catch(err=>{
        console.log(err)
      })
    },
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.userManagement.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },
    // 点击查询
    onSearch () {
      this.searchLoading = true
      const data = {
        loginName: this.form.loginName,
        userName: this.form.userName,
        companyId: this.form.companyId,
        roleId: this.form.roleId
      }
      this.form.currentPage = 1
      this.getDataList()
    },

    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getDataList()
    },

    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.getDataList()
    },
    // 新增用户
    onAddUser () {
      this.title = '新增用户'
      this.valueMultiple = []
      this.valueNameMultiple = ''
      this.valueAdd = null
      this.valueAddName = ''
      if (this.$refs.addForm) this.$refs.addForm.clearValidate()
      this.addForm.companyIds = []
      this.addForm.pcompanyId = null
      this.dialogVisible = true
    },
    // 点击表格查看按钮
    onUpdateUser (row) {
      this.title = '修改用户信息'
      this.addForm.id = row.id
      this.addForm.status = row.status
      this.addForm.userName = row.userName
      this.addForm.loginName = row.loginName
      this.addForm.phone = row.phone
      this.addForm.hdVideo = row.hdVideo
      this.addForm.wxStatus = row.wxStatus
      this.addForm.type = row.type || 0
      // console.log(212121,this.addForm.type)
      // this.getNewAndEditRoles(row.companyIds)
      this.$nextTick(() => {
        if (this.$refs.addForm) this.$refs.addForm.clearValidate()

        this.$refs.companySelect.clearHandle()
        this.$refs.authCompanySelect.clearHandle()
        this.valueMultiple = row.companyIds
        this.valueNameMultiple = row.companyNames.join()
        this.valueAdd = row.pcompanyId
        this.valueAddName = row.pcompanyName
        this.addForm.userGroupId = row.userGroupId ? row.userGroupId : ''
        this.addForm.roleIds = Array.from(row.roleIds, (item) => parseInt(item))
        
      })
      this.dialogVisible = true
    },
    // 弹窗关闭时的回调
    onDialogClose () {
      this.valueMultiple = []
      this.valueNameMultiple = ''
      this.valueAdd = null
      this.valueAddName = ''
      this.addForm = {
        id: '',
        type: 1,
        userName: '',
        loginName: '',
        password: '',
        phone: '',
        companyIds: [],
        pcompanyId: null,
        hdVideo: 0,
        wxStatus: 1,
        userGroupId: '',
        roleIds: []
      }
      this.$refs.addForm.clearValidate()
      this.$refs.companySelect.clearHandle()
      this.$refs.authCompanySelect.clearHandle()
      this.dialogVisible = false
      this.editLoading = false
    },
    //解锁账号
    unlockAccount(row){
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        type: 'warning',
        message: h('p', null, [
          h('span', null, '您将解锁用户 '),
          h('span', { style: 'color: #059370' }, row.userName),
          h('span', null, '  的账号，'),
          h('span', null, '，是否解锁？')
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          unLockUser({ userId: row.id }).then((res) => {
            if (res.code === 1000) {
              this.$message({
                type: 'success',
                showClose: true,
                message: '解锁账号成功!'
              })
              this.getDataList()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消解锁账号'
          })
        })
    
    },
    // 重置密码
    onResetPassword (row) {
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        type: 'warning',
        message: h('p', null, [
          h('span', null, '您将重置用户 '),
          h('span', { style: 'color: #059370' }, row.userName),
          h('span', null, '  的密码为'),
          h('span', { style: 'color: #059370' }, 'its123456'),
          h('span', null, '，是否重置？')
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          resetPwd({ userId: row.id }).then((res) => {
            if (res.code === 1000) {
              this.$message({
                type: 'success',
                showClose: true,
                message: '重置密码成功!'
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消重置密码'
          })
        })
    },

    // 禁用|启用 用户
    onDisableUser (row) {
      if (row.status === 0) {
        const h = this.$createElement
        this.$msgbox({
          title: '消息',
          type: 'warning',
          message: h('p', null, [
            h('span', null, '确定禁用用户 '),
            h('span', { style: 'color: #059370' }, row.userName),
            h('span', null, ' ？')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
          .then(() => {
            disableUser({ id: row.id, status: 1 }).then((res) => {
              if (res.code === 1000) {
                this.$message.success('禁用成功')
                row.status = 1
                
                // if (sessionStorage.getItem('username') === row.loginName) {
                //   sessionStorage.removeItem('token')
                //   location.reload()
                // }
                /**保持登录状态修复 */
                if (localStorage.getItem('username') === row.loginName) {
                  localStorage.removeItem('token')
                  location.reload()
                }
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              showClose: true,
              message: '已取消禁用'
            })
          })
      } else {
        disableUser({ id: row.id, status: 0 }).then((res) => {
          if (res.code === 1000) {
            this.$message.success('启用成功')
            row.status = 0
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    onAuthorizedVeh (row) {
      this.userId = row.id
      this.showAuthorizedVeh = true
    },
    // 获取用户列表
    getDataList () {
      getUserList(this.form).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error(res.msg)
          this.tableData = []
        }
        this.searchLoading = false
      })
    },
    // 新增/保存用户
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.editLoading = true

          let data = {
            userName: this.addForm.userName,
            loginName: this.addForm.loginName,
            phone: this.addForm.phone,
            companyIds: this.addForm.companyIds,
            pcompanyId: this.addForm.pcompanyId,
            userGroupId: this.addForm.userGroupId,
            hdVideo: this.addForm.hdVideo,
            wxStatus: this.addForm.wxStatus,
            roleIds: this.addForm.roleIds,
            type: this.addForm.type
          }
          if (this.title.indexOf('新增') !== -1) {
            const password = md5(this.addForm.password)
            data.password = password

            insertUser(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.onSearch()
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            data = {
              ...data,
              id: this.addForm.id,
              status: this.addForm.status
            }
            updateUser(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.getDataList()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
          this.onDialogClose()
        }
      })
    },
    // 业务公司
    getGroupId (v) {
      this.form.companyId = v
    },
    //新增- 业务公司ids
    getGroupIds (v) {
      this.addForm.companyIds = v
      if(v && v.length>0){
        this.getNewAndEditRoles(v)
      }else{
        this.chooseRoleList=[];
      }
     
    },
    //查询业务公司下所有公司对应角色--新增用户20210816谢--权限下发修改

    //新增- 权限公司id
    getAuthGroupId (v) {
      this.addForm.pcompanyId = v
    },
    getNewAndEditRoles (v) {
      const data = {
        companyIds:v
      }
      queryChooseRoles(data).then((res) => {
        if (res.code === 1000) {
          this.chooseRoleList = res.data;
        } else {
          this.$message.error(res.msg)
          this.chooseRoleList = []
        }
      })
    },
    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree().then((res) => {
        if (res.code === 1000) {
          this.companyList = res.data
        }
      })
    },
    // 获取权限企业树
    getAuthCompanyList () {
      getAuthCompanyTree({ isPermission: 0 }).then((res) => {
        if (res.code === 1000) {
          this.authCompanyList = [res.data]
        }
      })
    },
    // 获取用户组列表
    getUserGroups () {
      this.availableUserGroupList = []
      queryAllUserGroup().then((res) => {
        if (res.code === 1000) {
          res.data.forEach((item) => {
            if (item.status === 0) {
              this.availableUserGroupList.push(item)
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取角色列表
    getRoles () {
      queryChooseRoles({
        companyIds:[]
      }).then((res) => {
        if (res.code === 1000) {
          this.roleList = res.data
        } else {
          this.$message.error(res.msg)
          this.roleList = []
        }
      })
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: 'CZZH' }).then((res) => {
        if (res.code === 1000) {
          this.typeList = res.data.CZZH
        }
      })
    }
  },
  created () {
    this.onSearch()
    this.getCompanyList()
    this.getAuthCompanyList()
    this.getRoles()
    this.getUserGroups()
    this.getDicts()
    this.queryWeChatChooseStatus();
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.user-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-resourse {
  .user-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>

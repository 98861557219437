var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "userManagement", staticClass: "user-management" },
    [
      _c(
        "div",
        { ref: "functionButtons", staticClass: "function-buttons" },
        [
          _vm.nowMenuList.indexOf("新增") >= 0
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onAddUser }
                },
                [_vm._v("新增")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-position": "left",
                "label-width": "72px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户姓名：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户姓名" },
                    model: {
                      value: _vm.form.userName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "userName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.userName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户名：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户名" },
                    model: {
                      value: _vm.form.loginName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "loginName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.loginName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属公司：" } },
                [
                  _c("companySelect", {
                    attrs: {
                      clearable: true,
                      companyTree: _vm.companyList,
                      valueName: _vm.valueName,
                      value: _vm.value
                    },
                    on: { getValue: _vm.getGroupId }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择所属角色" },
                      model: {
                        value: _vm.form.roleId,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "roleId", $$v)
                        },
                        expression: "form.roleId"
                      }
                    },
                    _vm._l(_vm.roleList, function(role) {
                      return _c("el-option", {
                        key: role.id,
                        attrs: { label: role.roleName, value: role.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择状态" },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "禁用", value: 1 } }),
                      _c("el-option", { attrs: { label: "启用", value: 0 } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.searchLoading
                      },
                      on: {
                        click: function($event) {
                          return _vm.onSearch()
                        }
                      }
                    },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, stripe: "", height: _vm.tableHeight } },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: { prop: "userName", label: "用户姓名" }
          }),
          _c("el-table-column", {
            attrs: { prop: "loginName", label: "用户名" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "roles",
              label: "角色",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.roleNames.join("、")) + " ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "phone", label: "联系方式" }
          }),
          _c("el-table-column", {
            attrs: {
              label: "所属公司",
              prop: "companyIds",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " + _vm._s(scope.row.companyNames.join("、")) + " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              width: "360",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.nowMenuList.indexOf("编辑") >= 0 &&
                    scope.row.status === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onUpdateUser(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    (scope.row.status === 0
                    ? _vm.nowMenuList.indexOf("禁用") >= 0
                    : _vm.nowMenuList.indexOf("启用") >= 0)
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort:
                                scope.row.status === 0 ? "danger" : "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onDisableUser(scope.row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.status === 0 ? "禁用" : "启用")
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.nowMenuList.indexOf("重置密码") >= 0 &&
                    scope.row.status === 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "danger"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onResetPassword(scope.row)
                              }
                            }
                          },
                          [_vm._v("重置密码")]
                        )
                      : _vm._e(),
                    _vm.nowMenuList.indexOf("解锁账号") >= 0 &&
                    scope.row.status === 0 &&
                    scope.row.locking
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.unlockAccount(scope.row)
                              }
                            }
                          },
                          [_vm._v("解锁账号")]
                        )
                      : _vm._e(),
                    _vm.nowMenuList.indexOf("授权车辆") >= 0 &&
                    scope.row.type === 1
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onAuthorizedVeh(scope.row)
                              }
                            }
                          },
                          [_vm._v("授权车辆")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.title, visible: _vm.dialogVisible, width: "60%" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onDialogClose
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "body" },
              [
                _c(
                  "el-form",
                  {
                    ref: "addForm",
                    staticClass: "demo-form dialog-form dialog-form-inline",
                    attrs: {
                      model: _vm.addForm,
                      rules: _vm.rules,
                      "label-width": "82px",
                      "label-position": "left"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户姓名：", prop: "userName" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入用户姓名" },
                          model: {
                            value: _vm.addForm.userName,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "userName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.userName"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户名：", prop: "loginName" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入用户名",
                            disabled: _vm.title === "修改用户信息"
                          },
                          model: {
                            value: _vm.addForm.loginName,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "loginName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.loginName"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.title === "新增用户"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "密码：", prop: "password" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入密码" },
                              model: {
                                value: _vm.addForm.password,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "password",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addForm.password"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系方式：", prop: "phone" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入手机号码" },
                          model: {
                            value: _vm.addForm.phone,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "phone",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "addForm.phone"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "所属公司：", prop: "companyIds" } },
                      [
                        _c("companySelect", {
                          ref: "companySelect",
                          attrs: {
                            clearable: true,
                            multiple: true,
                            checkStrictly: false,
                            companyTree: _vm.companyList,
                            valueNameMultiple: _vm.valueNameMultiple,
                            valueMultiple: _vm.valueMultiple
                          },
                          on: { getValue: _vm.getGroupIds }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "权限公司：", prop: "pcompanyId" } },
                      [
                        _c("companySelect", {
                          ref: "authCompanySelect",
                          attrs: {
                            clearable: true,
                            valueName: _vm.valueAddName,
                            value: _vm.valueAdd,
                            companyTree: _vm.authCompanyList
                          },
                          on: { getValue: _vm.getAuthGroupId }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "高清视频：", prop: "hdVideo" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "" },
                            model: {
                              value: _vm.addForm.hdVideo,
                              callback: function($$v) {
                                _vm.$set(_vm.addForm, "hdVideo", $$v)
                              },
                              expression: "addForm.hdVideo"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "不可看", value: 0 }
                            }),
                            _c("el-option", {
                              attrs: { label: "可看", value: 1 }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "小程序：", prop: "wxStatus" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "",
                              disabled: !_vm.weChatChooseStatus
                            },
                            model: {
                              value: _vm.addForm.wxStatus,
                              callback: function($$v) {
                                _vm.$set(_vm.addForm, "wxStatus", $$v)
                              },
                              expression: "addForm.wxStatus"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "可登录", value: 0 }
                            }),
                            _c("el-option", {
                              attrs: { label: "不可登录", value: 1 }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户组：", prop: "userGroupId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择用户组" },
                            model: {
                              value: _vm.addForm.userGroupId,
                              callback: function($$v) {
                                _vm.$set(_vm.addForm, "userGroupId", $$v)
                              },
                              expression: "addForm.userGroupId"
                            }
                          },
                          _vm._l(_vm.availableUserGroupList, function(group) {
                            return _c("el-option", {
                              key: group.id,
                              attrs: {
                                label: group.name,
                                value: group.id,
                                disabled: group.status === 1
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "角色：", prop: "roleIds" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              multiple: "",
                              "collapse-tags": "",
                              clearable: "",
                              placeholder: "请选择所属角色"
                            },
                            model: {
                              value: _vm.addForm.roleIds,
                              callback: function($$v) {
                                _vm.$set(_vm.addForm, "roleIds", $$v)
                              },
                              expression: "addForm.roleIds"
                            }
                          },
                          _vm._l(_vm.chooseRoleList, function(role) {
                            return _c("el-option", {
                              key: role.id,
                              attrs: {
                                label: role.roleName,
                                value: role.id,
                                disabled: role.status === 1
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "车组账号：", prop: "type" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              placeholder: "请选择车组账号"
                            },
                            model: {
                              value: _vm.addForm.type,
                              callback: function($$v) {
                                _vm.$set(_vm.addForm, "type", $$v)
                              },
                              expression: "addForm.type"
                            }
                          },
                          _vm._l(_vm.typeList, function(item) {
                            return _c("el-option", {
                              key: item.dictCode,
                              attrs: {
                                label: item.dictValue,
                                value: Number(item.dictCode)
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "small" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    loading: _vm.editLoading
                  },
                  on: { click: _vm.onSave }
                },
                [_vm._v("保存")]
              )
            ],
            1
          )
        ]
      ),
      _vm.showAuthorizedVeh
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "授权车辆",
                visible: _vm.showAuthorizedVeh,
                width: "50%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.showAuthorizedVeh = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "body" },
                [
                  _c("authorizedVeh", {
                    ref: "authorizedVeh",
                    attrs: { userId: _vm.userId }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.showAuthorizedVeh = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

<template>
  <div class="body authorized-veh">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>新增绑定车辆</span>
      </div>
      <div class="text item">
        <el-form
          :model="addForm"
          :rules="rules"
          ref="addForm"
          label-position="left"
        >
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 12 }"
              placeholder="请输入车牌号，以回车分隔"
              v-model.trim="addForm.remark"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <section>
      <el-button
        type="primary"
        size="small"
        @click="onBind"
        :loading="isLoading"
        >批量绑定</el-button
      >
    </section>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>已绑定车辆</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          size="small"
          sort="danger"
          :disabled="carData.length == 0"
          @click="removeAllCar"
          >全部清空</el-button
        >
      </div>
      <div ref="container" class="tag-box">
        <div class="tag-section" v-if="carData.length > 0">
          <el-tag
            v-for="(tag, index) in carData"
            :key="tag.vehicleId"
            closable
            @close="removeCar(tag, index)"
          >
            {{ index + 1 }}.&nbsp;{{ tag.cph }}
          </el-tag>
        </div>
        <div v-else class="tag-section">暂无数据</div>
      </div>
    </el-card>
  </div>
</template>

<script>

import {
  getUserVehicleList,
  bindUserVehicles,
  unbindUserVehicles
} from '@/api/lib/api.js'

export default {
  components: {
  },
  props: {
    userId: {
      type: [String, Number],

    }
  },
  data () {
    return {
      addForm: {
        remark: null,
      },
      rules: {
        remark: [{ required: true, message: '请输入车牌号', trigger: 'blur' }],
      },
      carData: [], // 车辆数据
      list: Array.from({ length: 10 }, (_, i) => {
        return { cph: i }
      }), // 车辆数据仓库
      paperScrollSet: {
        startIndex: 0, // 从第一条截取
        endIndex: 20, //默认截取20条数据
        paddingTop: 0,// 撑起滚动元素的高度保证数据在视图
        paddingBottom: 0,//同top
        allHeight: 0,//滚动元素的总高
      },
      isLoading: false

    }
  },
  computed: {
    scrollList: function () {
      return this.list.slice(this.paperScrollSet.startIndex, this.paperScrollSet.endIndex);//实时截取数据
    },
    style: function () {
      return {
        paddingTop: this.paperScrollSet.paddingTop + 'px',
        paddingBottom: this.paperScrollSet.paddingBottom + 'px'
      }
    },
  },
  methods: {
    // 绑定车辆
    onBind () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.isLoading = true
          let remark = this.addForm.remark.replace(/\n/g, ",")
          let data = {
            userId: this.userId,
            cphList: remark.split(",")
          }
          bindUserVehicles(data).then(res => {
            if (res.code === 1000) {
              this.$message.success("操作成功");
              this.isLoading = false
              this.addForm.remark = ''
              this.getListByField()
            }else if (res.code === 1100) {
              this.$message.warning(res.msg);
              this.isLoading = false
              this.addForm.remark = ''
              this.getListByField()
            }
          })
        }
      })
    },
    // 滚动元素绑定的事件
    paperScroll () {
      const container = this.$refs.container;
      const top = container.scrollTop;
      this.paperScrollSet.startIndex = Math.floor(top / 42);
      this.paperScrollSet.endIndex = this.paperScrollSet.startIndex + 20;
      this.paperScrollSet.paddingTop = top;
      if (this.paperScrollSet.endIndex >= this.list.length - 1) {
        this.paperScrollSet.paddingBottom = 0
        return
      }
      this.paperScrollSet.paddingBottom = this.paperScrollSet.allHeight - 300 - top;//300为可视高度
    },
    //解除绑定
    removeCar (tag, index) {
      unbindUserVehicles({ userVehicleId: tag.id,userId: this.userId }).then(res => {
        if (res.code === 1000) {
          this.$message.success(res.msg);
          this.carData.splice(index, 1)
        }
      })
    },
    //解除全部绑定
    removeAllCar () {
      this.$confirm("是否全部清空绑定车辆?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          unbindUserVehicles({ userId: this.userId }).then(res => {
            if (res.code === 1000) {
              this.$message.success(res.msg);
              // this.$message.success(res.data || "操作成功");
              this.getListByField();

            }
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });

    },
    /** 取消 */
    clsoeDia (flag) {
      this.$emit('close', flag)
    },
    getListByField () {
      getUserVehicleList({ userId: this.userId }).then(res => {
        if (res.code === 1000) {
          this.carData = res.data
        }
      })
    }
  },
  created () {
  },
  mounted () {
    this.getListByField();
  },
  watch: {
    // list (val) {
    //   //监听数据变化更改滚动元素高度
    //   const valLength = val.length;
    //   this.paperScrollSet.allHeight = valLength * 42;//滚动内容单个元素的高度
    //   this.paperScrollSet.paddingBottom = this.paperScrollSet.allHeight - this.scrollList.length * 42;
    // }
  },
}
</script>

<style lang="scss" scoped>
.authorized-veh {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .box-card {
    width: 400px;
    height: 40vh;
  }
  .tag-box {
    height: 300px;
    overflow: auto;
    .tag-section {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      .el-tag {
        width: 32%;
        margin-right: 1%;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
